import * as React from "react"
// import { Link } from "gatsby"
import { InlineWidget } from "react-calendly"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SchedulePage = () => (
  <Layout>
    <Seo title="Schedule appointment" />

    <main>
      <div className="px-10 mx-auto mt-10 max-w-xlg text-center">
        <h1 className="mb-4 text-4xl text-gray-900 tracking-tight font-extrabold">
          Schedule a Consultation
        </h1>
        <p className="text-2xl text-indigo-600 font-semibold">
          I would love to help, no matter what your Medicare situation is.
        </p>
        <p className="mt-3 mb-3 sm:mt-3 sm:mb-0 text-2xl text-indigo-700 font-semibold">
          Schedule a time that works best for you and we can connect by phone or
          Google Meet or Zoom video call.
        </p>
      </div>
      <div className="mx-auto w-full h-full overflow-hidden">
        <InlineWidget
          styles={{
            height: "1000px",
            // overflow: "hidden",
            // paddingTop: "0",
            // marginTop: "0",
          }}
          url="https://calendly.com/micklesenior/medicare-consultation"
          utm={{}}
        />
      </div>
    </main>
  </Layout>
)

export default SchedulePage
